var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", { attrs: { title: "通知客服已汇款", back: true } }),
      _c("div", { staticClass: "bbox" }, [
        _vm._m(0),
        _c("div", { staticClass: "que" }, [
          _vm._v(" 您可以电话通知客服您已汇款，请回答以下两个问题： "),
        ]),
        _c("div", { staticClass: "box-items" }, [
          _c(
            "div",
            [
              _vm._v(" 您是通过哪种途径完成的汇款？ "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%", "margin-top": "5px" },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { value: "hongkong", label: "香港银行" },
                  }),
                  _c("el-option", {
                    attrs: { value: "mainland", label: "中国内地银行" },
                  }),
                  _c("el-option", {
                    attrs: { value: "other", label: "其他国家／地区银行" },
                  }),
                  _c("el-option", {
                    attrs: { value: "alipay", label: "支付宝上银汇款" },
                  }),
                ],
                1
              ),
              _vm.type == "mainland"
                ? _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%", "margin-top": "5px" },
                      on: {
                        focus: function ($event) {
                          _vm.tip1 = ""
                        },
                      },
                      model: {
                        value: _vm.bank,
                        callback: function ($$v) {
                          _vm.bank = $$v
                        },
                        expression: "bank",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: "ICBC", label: "中国银行" },
                      }),
                      _c("el-option", {
                        attrs: { value: "BOC", label: "中国工商银行" },
                      }),
                      _c("el-option", {
                        attrs: { value: "CCB", label: "中国建设银行" },
                      }),
                      _c("el-option", {
                        attrs: { value: "CEB", label: "中国光大银行" },
                      }),
                      _c("el-option", {
                        attrs: { value: "其他银行", label: "其他银行" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.tip1
            ? _c("div", { staticClass: "error-tips" }, [
                _vm._v(_vm._s(_vm.tip1)),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "money" },
            [
              _vm._v(" 您的汇款金额是？ "),
              _c(
                "el-input",
                {
                  staticStyle: { width: "100%", "margin-top": "5px" },
                  attrs: { placeholder: "请输入您的汇款金额" },
                  on: {
                    focus: function ($event) {
                      _vm.tip2 = ""
                    },
                  },
                  model: {
                    value: _vm.money,
                    callback: function ($$v) {
                      _vm.money = $$v
                    },
                    expression: "money",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "theme-color",
                      attrs: { slot: "prefix" },
                      slot: "prefix",
                    },
                    [_vm._v("$ ")]
                  ),
                ]
              ),
              _vm.tip2
                ? _c("div", { staticClass: "error-tips" }, [
                    _vm._v(_vm._s(_vm.tip2)),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "tips-1 theme-color" }, [
          _vm._v("* 相同信息提交一次即可，请勿重复提交。"),
        ]),
      ]),
      _c("div", { staticClass: "finacing-footer" }, [
        _c(
          "div",
          { staticClass: "add-btn theme-bgcolor", on: { click: _vm.open } },
          [_vm._v("提交汇款信息")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "theme-color" }, [
      _vm._v("完成转账充值后，请您耐心等待系统消息提醒。"),
      _c("br"),
      _vm._v(" 网银或支付宝转账一般需要1-2个工作日到账。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }