<template>
  <div>
    <Header :title="'通知客服已汇款'" :back="true"/>
    <div  class="bbox">
      <div class="theme-color">完成转账充值后，请您耐心等待系统消息提醒。<br/> 网银或支付宝转账一般需要1-2个工作日到账。 </div>
      <div class="que">
        您可以电话通知客服您已汇款，请回答以下两个问题：
      </div>
      <div class="box-items">
        <div>
        您是通过哪种途径完成的汇款？
        <el-select v-model="type" style="width:100%;margin-top:5px"> 
          <el-option value="hongkong" label="香港银行"></el-option>
          <el-option value="mainland" label="中国内地银行"></el-option>
          <el-option value="other" label="其他国家／地区银行"></el-option>
          <el-option value="alipay" label="支付宝上银汇款"></el-option>
        </el-select>
        <el-select v-if="type=='mainland'"  @focus="tip1=''" v-model="bank" style="width:100%;margin-top:5px">
          <el-option value="ICBC" label="中国银行"></el-option>
          <el-option value="BOC" label="中国工商银行"></el-option>
          <el-option value="CCB" label="中国建设银行"></el-option>
          <el-option value="CEB" label="中国光大银行"></el-option>
          <el-option value="其他银行" label="其他银行"></el-option>
        </el-select>
      </div>
      <div class="error-tips" v-if="tip1">{{tip1}}</div>
      <div class="money">
        您的汇款金额是？
        <el-input
        @focus="tip2=''"
         style="width:100%;margin-top:5px"
        placeholder="请输入您的汇款金额"
        v-model="money">
        <div class="theme-color"
          slot="prefix"
         >$
        </div>
      </el-input>
      <div class="error-tips" v-if="tip2">{{tip2}}</div>
      </div>
      </div>
      <div class="tips-1 theme-color">* 相同信息提交一次即可，请勿重复提交。</div>
    </div>
     <div class="finacing-footer">
      <div class="add-btn theme-bgcolor" @click="open">提交汇款信息</div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      type:this.$route.query.type||'hongkong',
      bank:'',
      money:'',
      tip1:'',
      tip2:''
    }
  },
  methods:{
    open() {
      if(!this.type){
        this.tip1="请选择汇款银行"
        return false
      }else if(this.type=='mainland'&&!this.bank){
        this.tip1="请选择汇款银行"
        return false
      }else if(!this.money){
        this.tip2="请输入汇款金额"
        return false
      }
      let params = {
        type:this.type,
        bank:this.bank,
        money:this.money
      }
      this.$store.dispatch('transation/recharges',params).then(res => {
        if(res.msg=='订单创建成功'){
          // const h = this.$createElement;
          this.$msgbox({
            title: '提交成功',
            center: true,
            type: 'success',
           
            message: <div>客服已经收到您的转账信息啦！一般1-2个工作日到账请耐心等待！<div style="margin-top:10px"><a style="color:#002D91;" href="tel:4000362988">我要电话催一催</a></div></div>,
            showCancelButton: false,
            confirmButtonText: '返回首页',
          
          }).then(() => {
            this.$router.replace({name:'home'})
          });
        }
      })
    }
  }
  
}
</script>
<style lang="scss" scoped>
.bbox{
  background: #F8F8F8;
  .theme-color{
    line-height: 1.5;
    margin: 2.5% 3.5%;
  }
  .que{
   margin: 2.5% 3.5%;
  }
  .money{
    margin-top: 5px;
    .theme-color{
      line-height: 40px;
      font-weight: 600;
      margin-left: 8px;
    }
  }
}
.finacing-footer{
  position: fixed;
  bottom: 0px;
  width: 95%;
  padding: 2.5%;
  .add-btn{
    border-radius:8px;
    width: 100%;
    text-align: center;
    line-height: 2.5;
    color: #fff;
  }
}
.error-tips{
  font-size: .9rem;
  color: #f56c6c;
}
</style>